import { Link, useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import Button from "react-bootstrap/Button";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ImageSection from "../../components/image-section";
import ServiceHero from "../../components/service-hero";
import { GoPrimitiveDot } from "react-icons/go";
import ServiceHeroMobile from "../../components/service-hero-mobile";
import SVGSC1 from "../../components/svg/sc1-svg";
import SVGSC2 from "../../components/svg/sc2-svg";
import SVGSC3 from "../../components/svg/sc3-svg";

const SSPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Suspended-Ceilings-Hero-SCP" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			WCCImg: wpMediaItem(title: { eq: "Why-Choose-Colston-Image-SCP" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			CTSImg: wpMediaItem(title: { eq: "Ceilings-To-Suit-Image-SCP" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			schemaLogo: wpMediaItem(title: { eq: "Colston-Logo" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
	const WCCImg = data.WCCImg?.localFile.childImageSharp.gatsbyImageData;
	const CTSImg = data.CTSImg?.localFile.childImageSharp.gatsbyImageData;

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id":
			"https://www.colston.ltd.uk/services/office-suspended-ceilings/#office-suspended-ceilings",
		image: `https://www.colston.ltd.uk${data.heroImg.localFile.publicURL}`,
		mpn: "https://www.colston.ltd.uk/services/office-suspended-ceilings",
		sku: "https://www.colston.ltd.uk/services/office-suspended-ceilings",
		description:
			"Enhance your office suspended ceilings with specialist tiles, sheets, or boards for optimal sound insulation, heat protection, and fire compliance.",
		logo: `https://www.colston.ltd.uk${data.schemaLogo.localFile.publicURL}`,
		name: "Colston Ltd",
		url: "https://www.colston.ltd.uk",
		brand: {
			"@type": "Organization",
			logo: `https://www.colston.ltd.uk${data.schemaLogo.localFile.publicURL}`,
			image: `https://www.colston.ltd.uk${data.heroImg.localFile.publicURL}`,
			name: "Colston Ltd",
			url: "https://www.colston.ltd.uk",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: "57",
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},
		// review: {
		// 	"@type": "Review",
		// 	url: "https://www.dentalhouseexeter.co.uk/reviews",
		// 	datePublished: "2022-03-21T17:58:29+00:00",
		// 	reviewBody:
		// 		"This is the best dental practice I have known. Not only in terms of expertise but the lovely sociability of every single member of the practice team.",
		// 	author: {
		// 		"@type": "Person",
		// 		name: "Caroline P",
		// 	},
		// 	reviewRating: {
		// 		"@type": "Rating",
		// 		ratingValue: "5",
		// 		bestRating: "5",
		// 		worstRating: "1",
		// 	},
		// 	publisher: {
		// 		"@type": "Organization",
		// 		name: "Dental House Exeter",
		// 		sameAs: "https://www.dentalhouseexeter.co.uk",
		// 	},
		// },
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Services",
				item: {
					url: `${siteUrl}/services`,
					id: `${siteUrl}/services`,
				},
			},

			{
				"@type": "ListItem",
				position: "3",
				name: "Office Suspended Ceilings",
				item: {
					url: `${siteUrl}/services/office-suspended-ceilings`,
					id: `${siteUrl}/services/office-suspended-ceilings`,
				},
			},
		],
	};

	return (
		<Layout>
			<div className="position-relative">
				<Helmet>
					{" "}
					<script type="application/ld+json">
						{JSON.stringify(breadcrumb)}
					</script>
				</Helmet>
				<Helmet>
					<script type="application/ld+json">
						{JSON.stringify(serviceSchema)}
					</script>
				</Helmet>

				<GatsbySeo
					title="Suspended office ceilings Eastbourne, East Sussex | Colston Ltd"
					description="Enhance your office suspended ceilings with specialist tiles, sheets, or boards for optimal sound insulation, heat protection, and fire compliance."
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}/services/office-suspended-ceilings`,
						title:
							"Suspended office ceilings Eastbourne, East Sussex | Colston Ltd",
						description:
							"Enhance your office suspended ceilings with specialist tiles, sheets, or boards for optimal sound insulation, heat protection, and fire compliance.",
						images: [
							{
								url: `${data.heroImg?.localFile.publicURL}`,
								width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
								height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
								alt: `${data.heroImg?.altText}`,
							},
						],
					}}
				/>
				<SVGSC1
					style={{ zIndex: "-1" }}
					className="position-absolute d-none d-lg-block top-10 start-0"
				/>
				<SVGSC2
					style={{ zIndex: "-1" }}
					className="position-absolute d-none d-lg-block top-30 end-0"
				/>
				<SVGSC3
					style={{ zIndex: "-1" }}
					className="position-absolute d-none d-lg-block bottom-0 start-0"
				/>
				<ServiceHero
					title={
						<span>
							Office
							<br /> Suspended
							<br /> Ceilings <br />
							in East
							<br /> Sussex
						</span>
					}
					subTitle="Does your office suspended ceiling need replacing?"
					description={
						<span>
							<p>
								We use all types of suspended ceiling tiles produced by the
								world’s leading manufacturers such as Armstrong/Zentia, AMF,
								Ecophon, Rockfon, Burgess and British Gypsum. We have extensive
								skill and experience in fitting flush plasterboard, grid type or
								suspended acoustic ceilings and baffles.
							</p>
							<p>
								We’ve seen lots of changes to the types of ceilings available on
								the market over the last 30 years. There are specific ranges of
								ceiling tiles to deal with these design requirements and we can
								advise you on the best type of drop ceiling tile for your
								circumstances, which have become increasingly complex and
								diverse to cover Clients’ different needs. We ensure we keep
								abreast of the latest developments so we can offer you the best
								advice for your drop ceiling system needs.
							</p>
						</span>
					}
					image={heroImg}
					imageAlt={data.heroImg?.altText}
					ctaText="Call our office suspended ceiling team for a free quote"
				/>
				<ServiceHeroMobile
					title={
						<span>
							<span>
								Suspended
								<br /> Ceilings
							</span>
						</span>
					}
					subTitle="Does your office suspended ceiling need replacing?"
					description={
						<span>
							<p>
								We use all types of suspended ceiling tiles produced by the
								world’s leading manufacturers such as Armstrong/Zentia, AMF,
								Ecophon, Rockfon, Burgess and British Gypsum. We have extensive
								skill and experience in fitting flush plasterboard, grid type or
								suspended acoustic ceilings and baffles.
							</p>
							<p>
								We’ve seen lots of changes to the types of ceilings available on
								the market over the last 30 years. There are specific ranges of
								ceiling tiles to deal with these design requirements and we can
								advise you on the best type of drop ceiling tile for your
								circumstances, which have become increasingly complex and
								diverse to cover Clients’ different needs. We ensure we keep
								abreast of the latest developments so we can offer you the best
								advice for your drop ceiling system needs.
							</p>
						</span>
					}
					ctaText="Call our office suspended ceiling team for a free quote"
				/>
				<section className="py-xl-10 pt-8">
					<ImageSection
						title="Suspended ceilings to suit your specific needs"
						image={CTSImg}
						imageAlt={data.CTSImg?.altText}
						text={
							<div>
								<p>
									Whatever you require from your office suspended ceiling
									system, whether it’s sound insulation, heat insulation or fire
									protection in order to comply with the newly introduced
									stricter fire safety protection regulations, we can help by
									providing specialist tiles, sheet or board ceilings that
									provide for optimal performance.
								</p>
								<p>
									At Colston we use the latest cutting-edge laser technology for
									our suspended ceiling installations and can design layouts in
									house, as well as providing the associated services involved
									in creating a new or refurbished suspended ceiling in your
									workplace, office or premises.
								</p>
								<h3>Our ceilings services include:</h3>

								<ul className="help-list mt-4 ps-0">
									<li className="d-flex align-items-center">
										<div
											style={{
												minWidth: "15px",
												height: "15px",
												borderRadius: "100%",
											}}
											className="bg-secondary d-flex justify-content-start me-3 ms-0 ps-0 "
										/>

										<p className="text-start text-primary fw-bold fs-5 pb-0 mb-0">
											{" "}
											New lighting, new LEDs
										</p>
									</li>
									<li className="d-flex  align-items-center">
										<div
											style={{
												minWidth: "15px",
												height: "15px",
												borderRadius: "100%",
											}}
											className="bg-secondary d-flex justify-content-start me-3 ms-0 ps-0 "
										/>

										<p className="text-start text-primary fw-bold fs-5 pb-0 mb-0">
											{" "}
											Air Conditioning or heating and cooling units
										</p>
									</li>
									<li className="d-flex align-items-center">
										<div
											style={{
												minWidth: "15px",
												height: "15px",
												borderRadius: "100%",
											}}
											className="bg-secondary d-flex justify-content-start me-3 ms-0 ps-0 "
										/>

										<p className="text-start text-primary fw-bold fs-5 pb-0 mb-0">
											{" "}
											Alarms
										</p>
									</li>
									<li className="d-flex align-items-center">
										<div
											style={{
												minWidth: "15px",
												height: "15px",
												borderRadius: "100%",
											}}
											className="bg-secondary d-flex justify-content-start me-3 ms-0 ps-0 "
										/>

										<p className="text-start text-primary fw-bold fs-5 pb-0 mb-0">
											{" "}
											Fire alarms
										</p>
									</li>
								</ul>
							</div>
						}
						imgSpan={6}
						textSpan={6}
						rounded="left-rounded"
						textPos="start--20 top-10"
						imgHeight="50rem"
						imgWidth="100%"
					/>
				</section>
				<section className="py-xl-10 py-5 mb-xl-7">
					<ImageSection
						title="Why choose Colston for your office suspended ceiling work?"
						image={WCCImg}
						imageAlt={data.WCCImg?.altText}
						text={
							<span>
								<p>
									As government regulations change and become stricter, it is
									essential for businesses to remain with new rules, such as
									being noise pollution neutral. In this case ceilings need to
									protect against sound transmission. There are specific ranges
									of sound absorption office drop ceiling grids and tiles
									designed for this and we can advise you on the best type of
									acoustic ceiling for your circumstances.
								</p>
								<p>
									As well as having good acoustic qualities, it is also
									important when planning a new ceiling to consider fire
									protection and heat insulation (which can help to reduce your
									energy bills in both residential and commercial spaces).
									Colston can guide you through this regulatory minefield so you
									can rest assured that you are fully compliant with
									the sometimes conflicting regulations and requirements.
								</p>
							</span>
						}
						order="last"
						imgSpan={6}
						textSpan={6}
						rounded="right-rounded"
						textPos="end--20 top-10"
						imgHeight="45rem"
						imgWidth="100%"
						cta={true}
						eHeight="100px"
						ctaPos="bottom--10 start--10"
						ctaText="Get in touch to discuss your suspended office ceiling requirements"
					/>
				</section>
			</div>
		</Layout>
	);
};

export default SSPage;
